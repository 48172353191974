import React from "react"
import { Container, SEO } from "../components"

const Contact = () => {
  return (
    <Container title="Contact">
      <SEO />
      <div>
        <h1>Contact</h1>
        <p>
          If you have a question about something I have developed, designed,
          written, have an issue or just interested to talk with me. Feel free
          and drop a word!
        </p>
        <div className="contactForm full">
          <form
            name="ContactForm"
            method="post"
            action="/success"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="ContactForm" />
            <input type="hidden" name="bot-field" />
            <p>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Full Name"
                required
              />
            </p>
            <p>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                required
              />
            </p>
            <p>
              <textarea
                id="message"
                name="message"
                placeholder="Your Message"
                required
              />
            </p>
            <div className="button">
              <button type="submit">Send</button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  )
}

export default Contact
